.add_btn {
    border-color: #ea5455 !important;
    background-color: #ea5455 !important;
    border-radius: 5px !important;
    color: white !important;
    font-weight: 500 !important;
    border: none !important;

}

.product_btns {
    display: flex;
    align-items: center;
}

.bulk_btn {
    color: #f34d4d !important;
    /* background-color: #f34d4d !important; */
    border-radius: 5px !important;
    padding: 0px 22px !important;
    border: 1px solid #f34d4d !important;
    font-weight: 500 !important;
    margin: 4px 13px;
    /* font-size: 15px !important; */

}



.table-row {
    font-size: 20px
}

.Deactive_status {
    background-color: #FCEAEA !important;
    border-radius: 5px;
    padding: 5px;
    color: #EA5455 !important;
    font-size: 12px;
    font-weight: 600;
}

.Active_status {
    background-color: #E2F8ED !important;
    border-radius: 5px;
    padding: 5px;
    color: #44CE80;
    font-size: 12px;
    font-weight: 600;
}

/* product_model coustom */


.model_product_wrapper {
    border: 1.5px #B3B3B3;
    border-style: dashed;
    padding: 20px;
    margin-top: 5px !important;
    max-height: 500px !important;
    justify-content: center !important;
}

.first_product_row {
    display: block !important;
    justify-content: space-between;

}

.ant-row {
    width: -webkit-fill-available;
    ;
}

.product_field_active {
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 27px;
}

.pro_list {
    display: flex;
    margin-bottom: 10px;
    background: #f0f0f0;
    padding:5px;
    border-radius: 5px;
}

.pro_list span {
    margin-right: 10px;
    min-width: 140px;
}

.main_wrapper .ant-table-body::-webkit-scrollbar {
    width: 0px;
}


/* .democlass .ant-row {
    width: 100% !important;
    justify-content: space-around !important;
} */
/* hr.new1 {
    border-top: 1px solid rgb(243, 238, 238) !important;
} */


/* IMAGE  */

.upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-right: 8px;
}

.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
    float: right;
}

/* .image_lists .ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
    border-color: none !important;
}

.image_lists .ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name,
.ant-upload-list-item-error .ant-upload-text-icon>.anticon {
    color: none !important;
} */
.info_icon {
    font-size: 18px;
    padding: 5px;
    color: #ea5455 !important;
    text-align: center;
}

.upload_btn {
    border: 1px dashed !important;
}

.upload_btn:focus,
.upload_btn:hover {
    color: #ea5455 !important;
    border-color: #ea5455 !important;
    background: #fff !important;
}

.upload_wrapper {
    display: flex;
    justify-content: space-between;
    /* align-items: center !important; */
}

.sample_btn {
    text-decoration: none !important;
    margin: 0 2px;
    /* color: black !important; */
}

.sample_btn:hover,
.sample_btn:focus {
    text-decoration: none !important;
    color: #ea5455 !important;
}

.ant-upload-list-item .ant-upload-list-item-error .ant-upload-list-item-list-type-text .ant-tooltip-open:hover {
    display: none !important;
    height: 0px !important;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: #ea5455 !important;
    color: #ea5455 !important;
}

/* upload  */


.file-input {
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    padding: 16px !important;
    width: 100% !important;
    height: 128px !important;
    position: relative !important;
    border-radius: 10px !important;
    background-color: #f2f8f8 !important;
    border: 1px dashed #00000059 !important;
}

@media (max-width: 500px) {
    .file-input {
        padding: 40px 0;
        width: 100%;
        height: 100%;
    }
}

.file-input>[type="file"] {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    opacity: 0 !important;
    z-index: 10 !important;
    cursor: pointer !important;
}

.file-input>.button {
    display: inline-block !important;
    cursor: pointer !important;
    background: #eee !important;
    padding: 8px 16px !important;
    border-radius: 2px !important;
    margin-right: 8px !important;
}

.file-input:hover>.button {
    background: dodgerblue !important;
    color: white !important;
}

.file-input>.label {
    color: #000 !important;
    white-space: nowrap !important;
}

.file-input.-chosen>.label {
    opacity: 1 !important;
}

.record_wrapper .browse_btn {
    border: 1px solid #00000059;
    padding: 7px 12px;
    border-radius: 5px;
}

.record_wrapper select:focus {
    box-shadow: none;
    border: 1px solid #dee2e6;
}

.select_duplicate {
    display: block !important;
}

hr.new1 {
    border-top: 0px solid rgb(231, 231, 231);
    /* margin-top: -1px; */

}

.bulk1_btn {
    padding: 0 !important;
}

.product_count {
    color: #ea5455 !important;
    font-size: 16px;
}

.ant-tooltip-inner {
    display: none !important;
}

.extra {
    margin: 4px 0 4px 13px !important
}

.info_text {
    color: gray !important;
}

.anticon-search {
    display: inline-block;
    font-style: normal;
    color: #60656b !important;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ant-input-number-affix-wrapper {
    width: 100% !important;
    height: 38px !important;
    border-radius: 8px !important;
}

.ant-input-number-affix-wrapper>div.ant-input-number {
    margin-top: 2px !important;
    width: 92% !important;
    border: none;
    outline: none;
}