.view_icon {
    color: #7367F0 !important;
    cursor: pointer ;
    font-size: 17px;
}

.delete_icon {
    color: #F40402 !important;
    cursor: pointer;
}
.location_icon{
    margin-right: 11px !important;

}
.edit_icon{
    margin: 0 0 0 15px;
}