body {
    /* overflow-y: hidden; */
}

/* Common css start */
.mr_1 {
    margin-right: 0.5rem;
}

.mr_2 {
    margin-right: 1rem;
}

.mr_3 {
    margin-right: 1.5rem;
}

.mr_4 {
    margin-right: 2.0rem;
}

.mr_5 {
    margin-right: 2.5rem;
}

.mr_6 {
    margin-right: 3rem;
}

/* Common css end */

/* Ant css */
/* .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before{
    position: absolute;
    right: -12px;
    top: 0;
} */


.form_control .ant-input {
    border-radius: 8px !important;
    height: 38px;
}

.form_control .ant-select-selector {
    border-radius: 8px !important;
    height: 33px !important;
}

.form_control .ant-input:hover {
    border-color: #f34d4d !important;
}

.form_control .ant-input-focused,
.ant-input:focus {
    border-color: #f34d4d !important;
    box-shadow: 0 0 0 2px rgba(253, 165, 165, 0.1) !important;
}

.form_control .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px !important;
}

.form_control .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 38px !important;
}

.form_control .ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 36px !important;
}

.form_control .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #f34d4d !important;
}

.form_prefix .ant-input {
    height: initial;
}

.form_control .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #f34d4d !important;
    box-shadow: 0 0 0 2px rgba(253, 165, 165, 0.1) !important;
}


.ant-popover-inner {
    border-radius: 8px;
    box-shadow: 0 0 15px #eee;
}

.ant-input-affix-wrapper {
    height: 38px !important;
    border-radius: 8px !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #f34d4d !important;
}

.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
    border-color: #f34d4d !important;
    box-shadow: 0 0 0 2px rgba(253, 165, 165, 0.1) !important;
}

.add_btn {
    border-color: #ea5455 !important;
    background-color: #ea5455 !important;
    border-radius: 5px !important;
    color: white !important;
    font-weight: 500 !important;
    border: none !important;
}

.add_btn:hover {
    background-color: #f34d4d !important;
    box-shadow: none !important;
}

.table-row {
    font-size: 20px
}

.site_header {
    justify-content: space-between;
    display: flex;
}


.site_table {
    background-color: #F3F2F7 !important;
}

.model_wrapper {
    border: 2px rgb(189, 189, 189);
    border-style: dashed;
    padding: 20px;
}

.site_form {
    display: block;
    justify-content: center;
    text-align: start;
    margin: 2px;
    position: relative;
}
.site_title .ant-modal-body{
    min-height: 300px;
    /* max-height: 500px; */
    overflow-y: scroll;
    overflow-x: hidden;
}

.product_lable {
    font-size: 13px;
    font-weight: 500;
    color: #041E42;
}

.model_lable {
    margin: 12px 0;
    font-weight: 600;
    color: #041E42;

}

.add_site {
    background-color: #D9D9D9 !important;
    height: 30px !important;
    padding: 3px !important;
    margin-top: 22px;

}

.ant-modal-content {
    border-radius: 10px !important;
}

.ant-modal-header {
    border-radius: 10px 10px 0 0 !important;
}

.ant-modal-footer {
    padding: 10px 16px;
    text-align: right;
    background: transparent;
    border-top: 1px solid #f0f0f0;
    border-radius: 0 0 2px 2px;
    justify-content: center !important;
    text-align: center !important;
}

.ant-btn-primary {
    background-color: #f34d4d !important;
    border: none !important;

}

.ant-modal-header {
    background-color: #231E20 !important;
}

.ant-modal-title {
    color: white !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    text-align: center;
}

.ant-modal-close-icon {
    color: white !important;
}

.site_input_group {
    /* width: 200px !important; */
    color: grey !important;

}

.site_input_group_1 {
    /* width: 200px !important; */
    color: black !important;
    font-weight: 500;
}

.site_input_group_2 {
    /* width: 200px !important; */
    color: black !important;
    font-weight: 500;
    width: 380px;
    max-height: 55px;
    overflow-y: scroll;
}

.site_input_group_2::-webkit-scrollbar {
    width: 4px !important;

}

.site_input_group_2::-webkit-scrollbar-thumb {
    background: #041E42;
    border-radius: 10px;
}

.site_input_group_employee {
    /* width: 400px !important; */
    color: gray !important;
}

.first_row {
    display: flex;
    /* padding: 10px; */
    justify-content: space-around;
}

.second_row {
    display: flex;
    /* padding: 10px 0; */
    justify-content: space-between;
    /* width: fit-content; */
}

.third_row {
    display: flex;
    padding-top: 25px;
    justify-content: flex-end;
    padding-bottom: 30px;
    position: sticky;
    bottom: -40px;
    background-color: #fff;
}

.user_field_active {
    padding: 0 10px;
}

.Deactive_status {
    background-color: #FCEAEA !important;
    border-radius: 5px;
    padding: 5px;
    color: #EA5455 !important;
    font-size: 12px;
    font-weight: 600;
}

.Active_status {
    background-color: #E2F8ED !important;
    border-radius: 5px;
    padding: 5px;
    color: #44CE80;
    font-size: 12px;
    font-weight: 600;
}

.add_site_btn {
    color: White !important;
    background-color: #f34d4d !important;
    border-radius: 7px !important;
    margin: 4px 0 4px 4px;
    padding: 0px 28px !important;
    border: 1px solid #f34d4d !important;
    font-size: 15px !important;
}

.Cancel_btn {
    color: #f34d4d !important;
    /* background-color: #f34d4d !important; */
    border-radius: 7px !important;
    padding: 0px 22px !important;
    border: 1px solid #f34d4d !important;
    margin: 4px;
    font-size: 15px !important;
}

.update_site_btn {
    color: White !important;
    background-color: #f34d4d !important;
    border-radius: 7px !important;
    margin: 4px;
    padding: 0px 20px !important;
    border: 1px solid #f34d4d !important;
    font-size: 15px !important;
}

.ok_site_btn {
    color: White !important;
    background-color: #f34d4d !important;
    border-radius: 7px !important;
    margin: 4px;
    padding: 0px 8px !important;
    border: 1px solid #f34d4d !important;
    font-size: 15px !important;
}

.icon_btn {
    border: none !important;
    padding: 0 !important;

}



/* loading Coustom  */

.ant-spin-dot-item {
    background-color: red !important;
}

.ant-table-wrapper {
    padding: 15px 0;
    max-height: fit-content !important;
}

.main_wrapper {
    min-height: 85vh !important;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
}

.modelWrapper {
    height: auto !important;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
}

.ant-pagination-item-active {
    border-color: #EA5455 !important;
}

.ant-pagination-item-active a {
    color: #EA5455 !important;
}

.ant-pagination-item:hover {
    border-color: #EA5455 !important;
}

.ant-pagination-item:hover a {
    color: #EA5455 !important;
}

.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link {
    color: #EA5455 !important;
    border-color: #EA5455 !important;
}

.profile_dropdown {
    padding: 10px !important;
    margin-top: 15px !important;
    border-radius: 6px !important;
}

.profile_dropdown .profile_name h2 {
    margin-bottom: 5px !important;
}

.btn_view {
    width: 35px !important;
    height: 35px !important;
    line-height: 26px !important;
    background-color: #7367F0 !important;
    border-color: #7367F0 !important;
    border-radius: 5px !important;
}

.btn_view path {
    fill: #fff;
    font-size: 25px;
}

.btn_edit {
    width: 35px !important;
    height: 35px !important;
    line-height: 26px !important;
    background-color: #4CCAB2 !important;
    border-color: #4CCAB2 !important;
    border-radius: 5px !important;
}

.btn_edit path {
    fill: #fff;
    font-size: 25px;
}

.btn_delete {
    width: 35px !important;
    height: 35px !important;
    line-height: 26px !important;
    background-color: #f34d4d !important;
    border-color: #f34d4d !important;
    border-radius: 5px !important;
}

.btn_delete path {
    stroke: #fff;
    font-size: 25px;
}


/* modal css start */
.model_wrapper_main {
    padding: 10px 15px;
}