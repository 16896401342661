/* SIDER COUSTOM  */
.logo_image {
  padding: 0 !important;
  width: 100px;
  justify-content: center;
  margin: 12px;
}


.ant-menu-item {
  color: #2C2E3D !important;
}

.ant-menu-inline .ant-menu-item:after {
  border: none !important;
}

.sider {
  background-color: white !important;
  height: 100vh;
}

.sider_logo {
  background-color: white !important;
  height: 70px;
  border-bottom: 0.1px solid #d9d9d9;
  text-align: center;
  cursor: pointer;
}

/* h4 {
  padding: 15px 25px;
} */

.sider_menu {
  background-color: white !important;
  color: grey !important;
  padding-top: 10px !important;
}

.site-layout .site-layout-background {
  background: #fff;
}

.sider_menu:active {
  background-color: none !important;
}

.nav-item {
  text-decoration: none;
  padding: 5px 25px;
}

.nav-link {
  color: grey !important;
}

.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: white !important;
}

.profile_item:hover {
  color: #2C2E3D !important;
  font-weight: 600;
}

.ant-menu-item-selected {
  background-color: #2C2E3D !important;
  color: white !important;
}

/* HEADER CUSTOM  */
.header_wrapper {
  border-bottom: 0.1px solid #d9d9d9 !important;
  display: flex;
  justify-content: space-between;
}

.input_search {
  width: 400px !important;
  padding: 0 30px;
  font-style: italic;
  font-weight: 200 !important;
}

.search_text {
  display: flex;
  align-items: center;

}

.profile_item {
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s;
}

.profile_link:hover {
  color: grey !important;

}

.profile_link {
  color: grey !important;

}

.site-layout-background {
  height: 70px !important;
}

.header_profile_link {
  /* padding: 0 22px 0 12px ; */
  color: #ea5455 !important;
  font-size: 18px;
  background-color: none;
  font-weight: 500 !important;
  margin-top: 0 !important;
  padding: 0 !important;
}

.ant-dropdown-trigger {
  border: none !important;
}


/* Content Coustom  */

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #3986ce;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(181, 219, 12, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}



/* ERROR COUSTOM  */
.error_logo {
  padding: 20px !important;
}

.error_content {
  padding: 40px;
}

.error_text_field {
  justify-content: center;
  text-align: center;

}

.error_text {
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: #5e5873;
  margin-bottom: 1rem !important;
}

.extra_text {
  font-family: Montserrat, Helvetica, Arial, serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
}

.error_btn {

  border-color: #ea5455 !important;
  background-color: #ea5455 !important;
  border-radius: 5px !important;
  color: white !important;
  font-weight: 500 !important;
  border: none !important;
  transition: 5s;
  box-shadow: 0 1px 5px 1px #ea5455 !important;
  margin-top: 20px;
}

/* Profile date  */

.date_formate {
  margin-top: 0 !important;
  font-size: 12px;
  color: #909090;
  /* display: block; */
}

.ant-col-19 {
  line-height: 22px;
  margin-bottom: 0px;
}

p {
  /* margin: 10px 0 0 0px !important; */
  line-height: 22px;
  margin-bottom: 0px !important;
}

.user_icon {
  margin-top: 17px;
  height: 45px;
  background-color: #FCEAEA;
  color: #EA5455;
  border-radius: 30px;

}

.welcome_text {
  color: #909090;
  margin-right: 7px;
}

.low_product {
  margin-left: 15px;
  font-size: 16px;
}


/* Top Header CSS START */
.top_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}