.add_btn {
    border-color: #ea5455 !important;
    background-color: #FE0707 !important;
    border-radius: 5px !important;
    color: white !important;
    font-weight: 500 !important;
    border: none !important;
    transition: 5s;

}

.action_icon {
    padding: 3px !important;
}

.table-row {
    font-size: 20px
}

.job_header {
    justify-content: space-between;
    display: flex;
    align-items: center;
}



.delete_icon {
    color: #F40402 !important;
    cursor: pointer;
}

.pending_task {
    background-color: #FFF0E8 !important;
    border-radius: 5px;
    padding: 5px 10px;
    color: #FF833A !important;
    font-size: 12px;
    font-weight: 600;
}

.inprocess_task {
    background-color: #F0FAFF !important;
    border-radius: 5px;
    padding: 5px 10px;
    color: #00A5F5 !important;
    font-size: 12px;
    font-weight: 600;
}

.completed_task {
    background-color: #E4FFF3 !important;
    border-radius: 5px;
    padding: 5px 10px;
    color: #01CE9A !important;
    font-size: 12px;
    font-weight: 600;
}

.job_table {
    background-color: #F3F2F7 !important;
}

.content_heading {
    color: #000000 !important;
    font-weight: 600;
    width: 700;
    font-size: 13px;

}

/* ADD JOBS COUSTOM  */
.model_wrapper {
    border: 1.5px #B3B3B3;
    border-style: dashed;
    padding: 20px;
    margin-top: 5px !important;
    max-height: 370px !important;
    overflow-y: scroll;
}

.model_wrapper::-webkit-scrollbar {
    width: 5px;
}

.job_form {
    display: flex;
    justify-content: space-around;
    text-align: start;
    margin: 2px;
}

.product_lable {
    font-size: 13px;
    font-weight: 500;
    color: #041E42 !important;
}

.product_lable_1 {
    opacity: 0.8;
    font-size: 15px;
    font-weight: 500;
    color: #041E42;
}

.product_lable_view {
    font-size: 13px;
    font-weight: 500;
    color: #041E42;
    display: flex !important;
}

.model_lable {
    margin: 12px 0;
    font-weight: 600;
    color: #041E42;

}

.model_lable_2 {
    margin: 12px 0;
    color: #041E42;
}

.model_lable_1 {
    display: block;
    /* justify-content: space-evenly; */
}

.ant-input {
    width: 350px;
}

.input_group {
    margin-top: 5px !important;
}

.ant-space-item {
    width: 100% !important
}

.add_job {
    /* border-color: #ea5455 !important; */
    background-color: #FCEAEA !important;
    border-radius: 5px !important;
    color: #EA5455 !important;
    font-weight: 500 !important;
    border: none !important;
    transition: 5s;
}

.addjob_row {
    margin-top: 20px;

}

.btn_div {
    display: flex;
    padding: 20px 5px;
    justify-content: flex-end;

}

.Main_contain {
    width: 100% !important;
    display: flex;
    justify-content: flex-start;
}

.contain {
    justify-content: center;
    text-align: start;
    width: 100%;


}

.addJob_btn {
    border-color: #ea5455 !important;
    background-color: #FE0707 !important;
    border-radius: 5px !important;
    color: white !important;
    margin-right: 7px;
}

.addJob_btn:hover {
    background-color: #f34d4d !important;
    box-shadow: none !important;
}



.job_form_data {
    margin-top: 7px;
}

.delete_job {
    color: #EA5455 !important;
    cursor: pointer;
    background-color: #FCEAEA !important;
    border: none !important;
    height: 30px !important;
    width: 30px;
    padding: 3px !important;

}

.jobcardNumber_field {
    width: 100% !important;
    margin-top: 5px !important;
}

.product_field {
    width: 100% !important;
    margin-top: 25px !important;
    border: none !important;
}

.product_field_name {
    width: 60% !important;
    margin-top: 25px !important;
    border: none !important;
    font-size: 13px;
    font-weight: 500;
    color: #041E42 !important;
}

.jobcardNumber_field_name {
    width: 70% !important;
    margin-top: 5px !important;
    border: none !important;
    font-size: 13px;
    font-weight: 500;
    color: #041E42 !important;
}

.Add_lable {
    color: #000000 !important;
    font-weight: 600;
    width: 600;
    font-size: 20px;

}

.add_job_lable {
    text-align: center;
    justify-content: center;
    margin-bottom: 20px;
}

/* .ant-row{
    justify-content: space-between;
} */
/* .ant-select {
    width: 270px;
}

.select_group {
    width: 270px !important;
} */

.ant-input-number {
    width: 150px !important;

}

/* TABLE COUSTOM  */

.ant-table-thead {
    color: #000000 !important;
}

.ant-table-thead>tr>th {
    background-color: #EAEBEC !important;

}

.ant-table-wrapper {
    padding: 0px 0 !important;
    max-height: max-content !important;
    margin: 15px 0 !important;
}

.ant-table-cell {
    color: #4b4b4b;

}

.ant-layout-header {
    background-color: #fff !important;
}

.ant-table.ant-table-middle .ant-table-tbody>tr>td,
.ant-table.ant-table-middle .ant-table-thead>tr>th,
.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle tfoot>tr>td,
.ant-table.ant-table-middle tfoot>tr>th {
    padding: 12px 15px !important;
}

.product_data {
    margin-top: 5px;
}


/* Veiw Table  */

.tabel_height {
    height: 720px !important;
    overflow-y: scroll !important;
}

.tabel_height_1 {
    height: 700px !important;
    overflow-y: scroll !important;
}

.tabel_height_1::-webkit-scrollbar {
    width: 0px !important;
}


.tabel_height::-webkit-scrollbar {
    width: 0px !important;
}

.add_product {
    align-items: center !important;
}

.add_product .ant-form-item {
    margin: 12px 0 12px;
}

.remove_job {
    text-align: center;
    font-size: 20px;
}

.add_new_product .ant-btn-dashed:hover {
    color: #EA5455 !important;
}

.ant-btn-dashed:focus,
.ant-btn-dashed:hover {
    color: #EA5455 !important;
    border-color: #EA5455 !important;
    background: #fff;
}

.edit_chassis_no {
    margin: 0 0 0 10px;
}

.chassis_no .ant-form-item-control-input {
    min-height: 0px !important;
}

.job_dropdown .ant-select-selector {
    width: 266px !important;
}

.job_dropdown {
    justify-content: space-between;

}

.report_wrapper .ant-card-extra {
    /* width: 50%; */
}

.ant-picker {
    padding: 7px 11px !important;
    border-radius: 8px !important;
}

.jobcard_items {
    margin: 0 0 0 92px !important;
}

.product_data {
    height: 300px;
    overflow-y: scroll;
}

.product_data::-webkit-scrollbar {
    width: 0px;
}

.report_wrapper .live_stock_wrapper .ant-card-body {
    min-height: 759px;
}

.report_wrapper .live_stock_wrapper .ant-card-body::-webkit-scrollbar {
    width: 0px;
}

.grand_total .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end;
    padding: 25px 10px 10px;
}

.grand_title {
    margin: -2px 25px 0 0;
    font-size: 16px;
}

.grand_text {
    margin: 0 35px 0 0;
}
.job_product_data{
    top:0 !important;
}


.job_product_data .ant-modal-body {
    min-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.job_product_data .ant-modal-body::-webkit-scrollbar {
    width: 3px;
    border-radius: 50px;
}

.job_product_data .ant-modal-body::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.39);
    border-radius: 50px;
}


.ant-btn-icon-only {
    padding: 4.4px 0 !important;
}

.title_div{
    display: flex;
    justify-content: space-between;
    margin-right: 30px;
}