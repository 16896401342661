.dashbord_content {
    font-size: 18px;
    font-weight: 500;
}

/* .row {
    justify-content: space-around;

} */

.Product_name {
    padding: 5px !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    text-align: start;
    color: #808080;
    width: 130px;
}

.product_icons {
    background-color: #EDEDFE !important;
    color: #8980F4 !important;
}

.values {
    padding: 3px !important;
    padding-top: 0 !important;
    margin: 0 !important;
    text-align: start;
    font-weight: 600;
    width: 80px;

}

.dash_product_field {
    width: 130px;
}

/* .col {
        display: flex !important;
        align-items: center;
    
    } */

.icons {
    font-size: 20px;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

/* 
.values {
    padding: 3px !important;
    padding-top: 0 !important;
    margin: 0 !important;
    text-align: start;
    font-weight: 600;
    width: 80px;

}

.dash_product_field {
    width: 130px;
}

.col {
    display: flex !important;
    align-items: center;

}

.icons {
    font-size: 20px;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}
.product_icons{
    background-color: #EDEDFE !important;
    color: #8980F4 !important;
}
.category_icons{
    background-color: #FEF3E8 !important;
    color: #F79F43 !important;
}
.employee_icons{
    background-color: #E3F9FC !important;
    color: #4AD2EA !important;
}.pending_icons{
    background-color: #FCEAEA !important;
    color: #EA5555 !important;
}.complete_icons{
    background-color: #E2F8ED !important;
    color: #4AD187 !important;
}
.ant-col-4 {
    justify-content: center;
}


.low_chart_field{
    padding-top:25px;
   
}
.chart_heding{
    display: flex;
    margin-bottom: 30px;
}
.chart_title{
color: black;
font-weight: 500;
font-size: 16px;
line-height: 24px;
} */


/* Dashboard css start */
.mb_30 {
    margin-bottom: 30px;
}

.dashboard_wrapper {}

.page_heading h1 {
    font-size: 25px;
    color: #181818;
}

.dashboard_wrapper .widgets_card {
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-bottom: 20px;
    /* height: 100%; */
}

.widgets_card p {
    font-size: 16px;
    color: #7e8299;
}

.widgets_card h2 {
    font-size: 30px;
    margin-bottom: 0;
    line-height: 1.2
}

.widgets_card .widgets_card_inner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.widgets_card .widgets_icon {
    width: 60px;
    height: 60px;
    line-height: 70px;
    text-align: center;
    background-color: #f34d4d;
    border-radius: 50%;
    margin-right: 15px;
}

.widgets_card .time_period_text {
    margin-bottom: 10px !important;
}

.b_1 {
    border: 1px solid #f3f6f9 !important;
}

.text_right {
    text-align: right !important;
}

.text_left {
    text-align: left !important;
}

.dashboard_wrapper .widgets_card .ant-select-selection-placeholder {
    text-align: left;
}

.dashboard_wrapper .live_stock_wrapper {
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    /* height: 100%; */
    min-height: 500px;
}

.dashboard_wrapper .job_card_wrapper {
    min-height: 775px;
}

.job_card_wrapper .job_details_icon svg {
    font-size: 22px;
}

.badge_count {
    display: inline-block;
    font-size: 12px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    background-color: #f34d4d;
    color: #fff;
    border-radius: 50%;
    position: relative;
    left: 3px;
}

.job_details_dropdown .ant-dropdown-menu {
    padding: 10px !important;
    border-radius: 8px !important;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%) !important;
}

.ant-dropdown-menu {
    padding: 6px 0 !important;
    border-radius: 8px !important;
}

.ant-dropdown-menu-item {
    padding: 5px 20px !important;
}

.live_stock_wrapper .live_stock_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.live_stock_wrapper .ant-card-head-title {
    font-size: 20px !important;
}

.live_stock_wrapper .ant-card-extra a {
    font-size: 15px;
    color: #7e8299 !important;
    /* rgb(243,1,6) */
}

.live_stock_wrapper .ant-list-item-meta-title a {
    font-size: 16px;
}

.live_stock_count .ant-btn-primary {
    min-width: 35px;
    height: 35px;
}

.live_stock_wrapper .ant-card-body {
    min-height: 560px;
    overflow-y: auto;
    max-height: 580px;
    padding-top: 5px;
}

.live_stock_wrapper .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #f34d4d;
}

.live_stock_wrapper .ant-tabs-ink-bar {
    background: #f34d4d;
}

.live_stock_wrapper .ant-tabs-tab:hover {
    color: #f34d4d;
}

.live_stock_wrapper .ant-tabs-tab-btn:active,
.live_stock_wrapper .ant-tabs-tab-btn:focus,
.live_stock_wrapper .ant-tabs-tab-remove:active,
.live_stock_wrapper .ant-tabs-tab-remove:focus {
    color: #f34d4d;
}

/* width */
.live_stock_wrapper .ant-card-body::-webkit-scrollbar {
    width: 2px;
}

/* Track */
.live_stock_wrapper .ant-card-body::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.live_stock_wrapper .ant-card-body::-webkit-scrollbar-thumb {
    background: rgb(170, 170, 170);
    border-radius: 10px;
}

/* Handle on hover */
.live_stock_wrapper .ant-card-body::-webkit-scrollbar-thumb:hover {
    background: rgb(141, 141, 141);
}

.custom-list-spin {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}