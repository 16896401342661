 .login_btn {
   background: #F34D4D !important;
   color: #ffffff !important;
   border-radius: 5px !important;
   height: 36px !important;
   border: none !important;
 }
 .login_btn:hover{
    background: #F34D4D !important;
   color: #ffffff !important;
   border-color: #F34D4D !important;   
 }

 #email,
 #password {
   color: #041E42;
   line-height: 20px;
   font-size: 15px !important;
   font-weight: 400 !important;
 }

 .input_lable {
   color: #041E42;
   line-height:
     21.78px;
     text-align: start !important;
 }

 .login_form {
    width: 460px;
    background: transparent;
    box-shadow: 0px 67px 88px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
 }

 .login_form_content {
  background: white;
  border-radius:0 0 9px 9px;
   padding: 40px !important;

 }

 .login_background {
   height: 100vh;
   /* background-color: #CACACA; */
   justify-content: center;
   align-items: center;
   text-align: center;
   display: flex;
   background-image: url('../../assets/images/bg-login.png');
   background-size: cover;  
 }

 .input_field {
   color: #041E42 !important;
   border: 1px solid rgba(4, 30, 66, 0.1) !important;
   text-align: initial;
   width: 370px !important;
 }

 .forgot_text {
   color: rgb(4, 30, 66);
   cursor: pointer;
   margin-bottom: 10px !important;
   text-align: end;
 }


 .form_item {
   margin-bottom: 15px !important;
   text-align: initial;
 }

 .form_lable {
   padding: 5px 20px;
   text-align: left;
   color: #ffffff;
   line-height: 40px;
   font-weight: 400;
   font-size: 18px !important;
   border-radius: 9px 9px 0 0;
 }
 .Input_div{
  display: flex;
  justify-content: space-between;
  width: 100vh;
  align-items: center;
 }
.Login_logo{
  background: #231E20;
  border-radius: 9px 9px 0 0;
  text-align: left;
  padding: 5px 5px;
}
.logo_login{
  margin-bottom: 20px;
}